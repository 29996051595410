<template>
  <div>
    <h3>标题</h3>
    <hr data-text="分隔线">
  </div>
</template>

<style lang="less">
    hr{
      position: relative;
      overflow: visible;
      color: #ccc;
      border: 0;
      border-bottom: 1px solid #eee;
    }
    hr::after{
      position:absolute;
      display: inline-block;
      content:attr(data-text);
      padding:5px 10px;
      top:-14px;
      left:calc(50% - 34px);
      background-color: white;

    }
  </style>